import React from 'react';
import './DocModal.scss';

const DocModal = ({ isVisible, title, content, footer, onClose, hideClose, hideBody }) =>
  !isVisible ? null : (
    <div className='modal'>
      <div className='modal-dialog'>
        <div className='modal-header'>
          <h3 className='modal-title'>{title}</h3>
          {typeof hideClose === 'undefined' && (
            <span className='modal-close' onClick={onClose}>
              <i className='fa fa-times close-button'></i>
            </span>
          )}
        </div>
        {typeof hideBody === 'undefined' && (
          <div className='modal-body'>
            <div className='modal-content'>{content}</div>
          </div>
        )}
        {footer && <div className='modal-footer'>{footer}</div>}
      </div>
    </div>
  );
export default DocModal;
