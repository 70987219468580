import axios from 'axios';

const DrugsServiceKey =
	typeof process.env.GATSBY_DRUGS_SERVICE_KEY === 'undefined'
	  ? 'Bearer tiOcuZJtaZGBIjX'
	  : `Bearer ${process.env.GATSBY_DRUGS_SERVICE_KEY}`;
const CountriesServiceKey =
	typeof process.env.GATSBY_COUNTRIES_SERVICE_KEY === 'undefined'
	  ? 'Bearer Aj02FrDzwdRHdhoH'
	  : `Bearer ${process.env.GATSBY_COUNTRIES_SERVICE_KEY}`;

const ReportServiceKey =
	typeof process.env.GATSBY_REPORT_SERVICE_KEY === 'undefined'
	  ? 'Bearer N9fmB143auMS1rAEUkEvp6lP2ityyUR'
	  : `Bearer ${process.env.GATSBY_REPORT_SERVICE_KEY}`;

const allSvc = {
  autocomplete(baseUrl) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${baseUrl}/v1/drug`,
        headers: {
          Authorization: `${DrugsServiceKey}`,
        },
        method: 'get',
      })
        .then(response => {
          if ((response.status === 200 || response.data.status === 'ok') && response.data) {
            resolve({ success: true, data: response.data.data });
          } else {
            reject({ success: false, error: 'Unable to get drugs list.' });
          }
        })
        .catch(err => {
          reject(err);
          console.error(err);
        });
    });
  },
  getCountries(baseUrl) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${baseUrl}/v1/country`,
        method: 'get',
      })
        .then(response => {
          if ((response.status === 200 || response.data.status === 'ok') && response.data) {
            resolve({ success: true, data: response.data.data });
          } else {
            reject({ success: false, error: 'Unable to get countries list.' });
          }
        })
        .catch(err => {
          reject(err);
          console.error(err);
        });
    });
  },
  updateUsageStats(baseUrl, dataObj) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'patch',
        url: `${baseUrl}/v1/medicine-restrictions/usage-stats-views-inc`,
        data: dataObj,
      })
        .then(response => {
          if (response.status === 200 || response.data.status === 'ok') {
            resolve({ success: true });
          } else {
            reject({ success: false, error: 'Error sending report' });
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createReport(baseUrl, dataObj, company) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: `${baseUrl}/v1/medicine-restrictions/report${company ? `?company=${company}` : ''}`,
        data: dataObj,
      })
        .then(response => {
          if (response.status === 200 || response.data.status === 'ok') {
            resolve({ success: true, reportId: response.data.data.id });
          } else {
            reject({ success: false, error: 'Error sending report' });
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getReport(baseUrl, reportId, company) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: `${baseUrl}/v1/medicine-restrictions/report/${reportId}${company ? `?company=${company}` : ''}`,
      })
        .then(response => {
          if (response.status === 200 || response.data.status === 'ok') {
            resolve({ success: true, data: response.data.data });
          } else {
            reject({ success: false, error: 'Error sending report' });
          }
        })
        .catch(err => reject(err));
    });
  },
  emailReport(baseUrl, reportId, data, company) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'patch',
        url: `${baseUrl}/v1/medicine-restrictions/report/${reportId}${company ? `?company=${company}` : ''}`,
        data: {
          user_information: {
            email: data.email,
            terms_accept: data.terms_accept,
            further_information_accept: data.further_information_accept,
          },
        },
      })
        .then(response => {
          if (response.status === 200 || response.data.status === 'ok') {
            resolve({
              success: typeof response.data.user_information !== 'undefined',
            });
          } else {
            reject({
              success: false,
              error: 'Error sending report as an email',
            });
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};

export default allSvc;
